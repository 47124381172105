<template>
  <div class="bitts-select-tags" :class="{ error }">
    <BittsFormLabel v-if="formLabel" :label="formLabel" :disabled="disabled" />
    <ASelect
      ref="tagsInput"
      v-model:value="value"
      data-testid="bitts-select-tags"
      :allow-clear="allowClear"
      :autofocus="autoFocus"
      :disabled="disabled"
      :token-separators="[',', ' ']"
      size="large"
      mode="tags"
      popup-class-name="bitts-select-tags__dropdown"
      @change="handleChange"
      @search="handleSearch"
    >
      <template #placeholder>
        <div class="bitts-select__placeholder">
          <div v-if="prefixIcon" class="flex items-center">
            <FontAwesomeIcon
              :icon="prefixIcon"
              :style="{ width: '16px', color: 'currentColor' }"
              class="mr-8"
            />
            {{ placeholder }}
          </div>
          <div v-else>
            {{ placeholder }}
          </div>
        </div>
      </template>
      <template #suffixIcon>
        <FontAwesomeIcon
          :icon="['fas', 'chevron-down']"
          class="text-neutral-accent"
          :style="{ width: '8px', color: 'currentColor' }"
        />
      </template>
      <template #removeIcon>
        <FontAwesomeIcon
          :icon="['fas', 'close']"
          class="text-neutral-accent"
          :style="{ width: '8px', color: 'currentColor' }"
        />
      </template>
    </ASelect>
    <p v-if="!warningMessage && note" class="bitts-select-tags__note">
      {{ note }}
    </p>
    <BittsAlert
      v-if="warningMessage"
      :message="warningMessage"
      color="warning"
      class="mt-8 mb-16"
    />
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Select as ASelect } from 'ant-design-vue';

import BittsAlert from '../BittsAlert/BittsAlert.vue';
import BittsFormLabel from '../BittsFormLabel/BittsFormLabel.vue';

export default {
  name: 'BittsSelectTags',
  components: {
    ASelect,
    BittsAlert,
    BittsFormLabel,
    FontAwesomeIcon,
  },
  props: {
    allowClear: {
      type: Boolean,
      default: true,
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Emails, separated by commas',
    },
    error: {
      type: Boolean,
      default: false,
    },
    prefixIcon: {
      type: [String, Object, Array],
      default: null,
    },
    note: {
      type: String,
      default: null,
    },
    warningMessage: {
      type: String,
      default: null,
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
    formLabel: {
      type: [Object, String],
      default: null,
    },
  },
  emits: ['change', 'search', 'update:modelValue'],
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    handleChange(val) {
      this.$emit('change', val);
    },
    handleSearch(val) {
      this.$emit('search', val);
    },
  },
};
</script>

<style lang="pcss">
.bitts-select-tags__note {
  @apply text-sm text-neutral-text-weak mt-8;
}

.bitts-select-tags {
  .ant-select {
    font-family: inherit;
  }
  .ant-select-multiple.ant-select-lg .ant-select-selection-search {
    @apply ms-0;
  }
  .ant-select-multiple .ant-select-selection-item {
    @apply rounded-4 border-none bg-neutral-background text-neutral-text h-24 leading-6;
    .ant-select-selection-item-content {
      @apply pr-4 text-neutral-text;
    }
  }
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
      .ant-pagination-size-changer
    ):hover
    .ant-select-selector {
    @apply border-neutral-border;
  }
  .anticon-close-circle {
    @apply relative bottom-3;
  }
  .ant-select {
    @apply text-base leading-6 w-full;
  }
  .ant-select-selection-selected-value {
    @apply text-neutral-text;
  }

  .ant-select-selection-search {
    @apply ms-0;
  }

  .ant-select-selection-overflow {
    @apply mx-8;
  }
  .ant-select-arrow .ant-select-arrow-icon {
    @apply transition-transform;
  }
  .ant-select-open .ant-select-arrow-icon {
    @apply rotate-180;
  }
  .ant-select-selector {
    @apply flex-nowrap max-h-[160px] overflow-y-auto;
    scrollbar-width: thin;
  }
  .ant-select-clear {
    @apply mr-8;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    @apply rounded-lg border-neutral-border transition-none shadow-component;
  }
  .ant-select-selection-placeholder {
    @apply text-neutral-text-placeholder ml-4;
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    @apply bg-neutral-background-disabled;
  }
  .ant-select-multiple.ant-select-lg .ant-select-selection-item {
    @apply h-24 leading-6;
  }
  .ant-select-selection--multiple .ant-select-selection-item-remove {
    @apply right-8;
  }
  &.error .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none !important;
    border-right-width: 2px !important;
    @apply border-danger-border-focus border-2;
  }
}
.ant-select-dropdown-menu-item,
.ant-select-dropdown-menu-item-group-list
  > .ant-select-dropdown-menu-item.ant-select,
.ant-select-dropdown-menu-item-group-list > .ant-select-dropdown-menu-item {
  @apply text-neutral-text-strong py-5 px-8 rounded;
}

.ant-select-dropdown-menu-item-active:not(
    .ant-select-dropdown-menu-item-disabled
  ) {
}
.ant-select-dropdown-menu-item:hover:not(
    .ant-select-dropdown-menu-item-disabled
  ) {
  @apply bg-neutral-background;
}
.ant-select-selection:focus,
.ant-select-selection:active {
  box-shadow: 0 0 0 1px theme(colors.neutral.border-focus);
  @apply border-neutral-border-focus;
}

.ant-select-dropdown-menu-item-group-title {
  @apply p-0 h-auto;
}
.ant-select-dropdown-menu-item-group:not(:last-child) {
  @apply border-b border-b-neutral-border pb-4 mb-4;
}
.ant-select-dropdown-menu {
  @apply p-4;
}
.ant-select-dropdown-menu-item-group-list
  .ant-select-dropdown-menu-item:first-child:not(:last-child),
.ant-select-dropdown-menu-item-group:not(:last-child)
  .ant-select-dropdown-menu-item-group-list
  .ant-select-dropdown-menu-item:last-child {
  @apply rounded-4;
}

.bitts-select-tags__dropdown {
  @apply border border-neutral-border rounded-8 hidden;
  &.ant-select-dropdown--multiple
    .ant-select-dropdown-menu-item-selected
    .ant-select-selected-icon,
  &.ant-select-dropdown--multiple
    .ant-select-dropdown-menu-item-selected:hover
    .ant-select-selected-icon {
    @apply text-info-accent;
  }
  &.ant-select-dropdown--multiple
    .ant-select-dropdown-menu-item:hover
    .ant-select-selected-icon {
    @apply text-neutral-text-strong;
  }
  .ant-select-dropdown-menu-item-active {
    @apply bg-neutral-background;
  }
  .ant-select-dropdown-menu-item-selected {
    @apply bg-info-background-weak font-normal;
  }
}
</style>
